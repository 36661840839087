<div *ngIf="percentage | async as pct">
    <progress [value]="pct" max="100"></progress>
    {{ pct | number }}%
  </div>
  
  
  
  <div *ngIf="snapshot | async as snap">
  
    {{ snap.bytesTransferred }} of {{ snap.totalBytes }} 
  
    <div *ngIf="downloadURL as url">
      <img [src]="url" onerror="this.src='assets/images/file-text.svg'" height="50px" width="50px"><br>
      <a [href]="url" target="_blank" rel="noopener">{{file.name}}</a>

      <div fxLayout="row">
        <button mat-icon-button [cdkCopyToClipboard]="url" title="Copy full link">
          <mat-icon>reorder</mat-icon>
        </button>
        <button mat-icon-button [cdkCopyToClipboard]="shortLink?.shortLink" title="Copy short link">
          <mat-icon>link</mat-icon>
        </button>
      </div>

    </div> 


  
    <button mat-icon-button (click)="task.pause()" [disabled]="!isActive(snap)">
      <mat-icon>pause_circle_outline</mat-icon>
    </button>
    <button mat-icon-button (click)="task.cancel()" [disabled]="!isActive(snap)">
      <mat-icon>cancel</mat-icon>
    </button>
    <button mat-icon-button (click)="task.resume()" [disabled]="!(snap?.state === 'paused')">
      <mat-icon>play_circle_outline</mat-icon>
    </button>
  </div>