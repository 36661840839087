import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-user-certificate',
  templateUrl: './user-certificate.component.html',
  styleUrls: ['./user-certificate.component.scss']
})
export class UserCertificateComponent implements OnInit {
  @Input() pdfData;
  @Input() pdfUrl: string;
  @Input() programTitle: string;
  @Input() primaryColor: string;
  @Input() type: string = 'program';
  @Input() secondaryColor: string;
  datepipe: DatePipe = new DatePipe('en-US')


  constructor(private http: HttpClient) { }

  async ngOnInit(): Promise<void> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');

    let certPdf;

    if(this.pdfUrl){
      certPdf = await this.http.get(this.pdfUrl, { headers: headers, responseType: 'blob' }).toPromise().then(res=> res.arrayBuffer());
    } 

    if(this.pdfData){
      certPdf = await this.dataURLtoBlob(this.pdfData).arrayBuffer()
    }
    


    const montFontSemi = await this.http.get('https://firebasestorage.googleapis.com/v0/b/omigcslogic-code-dev.appspot.com/o/Montserrat-SemiBold.ttf?alt=media&token=1495af68-25aa-4f8e-8e35-77963eb6b885', { headers: headers, responseType: 'blob' }).toPromise().then(res=> res.arrayBuffer());
    const montFont = await this.http.get('https://firebasestorage.googleapis.com/v0/b/omigcslogic-code-dev.appspot.com/o/Montserrat-Regular.ttf?alt=media&token=5409afc7-8960-4249-a7e7-d62277d0f53c', { headers: headers, responseType: 'blob' }).toPromise().then(res=> res.arrayBuffer());
    const bilboFontSemi = await fetch('https://firebasestorage.googleapis.com/v0/b/omigcslogic-code-dev.appspot.com/o/Bilbo-Regular.ttf?alt=media&token=9117fe57-e738-474e-b3d3-4b07bbfcb0e5').then((res)=> res.arrayBuffer());

    const newCertPdf = await PDFDocument.load(certPdf);
    newCertPdf.registerFontkit(fontkit)
    const bilbo = await newCertPdf.embedFont(bilboFontSemi);
    const montSemi = await newCertPdf.embedFont(montFontSemi);
    const mont = await newCertPdf.embedFont(montFont);
    const pages = newCertPdf.getPages();
    const firstPage = pages[0];

    const pRgbArray = this.rgbaStringToArray(this.primaryColor)
    const sRgbArray = this.rgbaStringToArray(this.secondaryColor)

        //draw name
   firstPage.drawText("Your\nName-Here", {
    x: 77,
    lineHeight: 69,
    y: 400,
    size: 58,
    font: bilbo,
    color: rgb(parseInt(sRgbArray[0])/255, parseInt(sRgbArray[1])/255, parseInt(sRgbArray[2])/255),
  });

  //draw cert reason
  firstPage.drawText(`for completing the OmicsLogic\n${this.programTitle} ${this.type}.`, {
    x: 77,
    lineHeight: 24,
    y: 284,
    size: 16,
    font: mont,
    color: rgb(0,0,0),
  });

  //draw date
  firstPage.drawText(this.datepipe.transform(new Date(), 'd MMM y'), {
    x: 337,
    y: 186,
    size: 16,
    font: montSemi,
    color: rgb(0,0,0),
  });

    //draw trainer name
    firstPage.drawText('Dr Harpreet Kaur', {
      x: 77,
      y: 186,
      size: 16,
      font: montSemi,
      color: rgb(0,0,0),
    });

  const pdfBytes = await newCertPdf.save();
  const blob = new Blob([pdfBytes], {type:'application/pdf'})
  this.pdfData = URL.createObjectURL(blob);

  }

  rgbaStringToArray(rgbaString: string){
    return rgbaString.replace(/[^\d,]/g, '').split(',');;
  }

  dataURLtoBlob(dataurl:string) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
}

}
