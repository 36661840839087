import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../shared/services/auth/auth.service';
import { take, switchMap } from 'rxjs/operators';
import { DatabaseService } from '../../shared/services/database/database.service';
import { SnackService } from '../../shared/services/snack/snack.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private auth: AuthService, private db: DatabaseService, private router: Router, private snack: SnackService){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.auth.signedInUser.pipe(take(1), 
      switchMap( async (user:any) =>{
        if(user){
          const userRole = await this.db.getUserRole(user.uid);
          if(userRole === "admin"){
            // this.router.navigateByUrl('/dashboard');
            // this.snack.generalSnack('Success!', 'Ok');
            return true;
          } else {
            this.auth.signOutUser();
            this.router.navigate['/']
            return false;
          }
        } else {
          this.auth.signOutUser();
          this.router.navigate['/']
          return false;
        }
      })
      )
  }
  
}
