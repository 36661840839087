import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import md from 'markdown-it';
import * as md_video from 'markdown-it-video';
import * as md_emjoi from 'markdown-it-emoji';
import * as hljs from 'highlight.js';

@Component({
  selector: 'app-markdown-block',
  templateUrl: './markdown-block.component.html',
  styleUrls: ['./markdown-block.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class MarkdownBlockComponent implements OnInit {
  @Input() set markdownContent(value: string){
    this.markdown.set({
      highlight: function (str, lang) {
        if (lang && hljs.getLanguage(lang)) {
          try {
            return hljs.highlight(lang, str).value;
          } catch (e) {
            console.log(e)
          }
        }
        return ''; // use external default escaping
      }
    })
    this.outHtml=this.markdown.render(value ? value : '');
  }
  private markdown;
  outHtml;
  constructor() {
    this.markdown=md(
      {
        html: true,
        linkify: true,
        highlight: function (str, lang) {
          if (lang && hljs.getLanguage(lang)) {
            try {
              return hljs.highlight(lang, str).value;
            } catch (__) {}
          }
       
          return ''; // use external default escaping
        }
      }).use(md_video,
      {
        youtube: { width: 640, height: 390 },
        vimeo: { width: 500, height: 281 },
        vine: { width: 600, height: 600, embed: 'simple' },
        prezi: { width: 550, height: 400 }
      }).use(md_emjoi)
   }

  ngOnInit(): void {

  }

}
