<div class="card-back" [ngStyle.xs]="{'height': '100%'}">
    <h4>Top Users:</h4>
    <mat-chip-list></mat-chip-list>
    <div *ngIf="topUsers |async as topUsers" fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="16px">

        <div fxLayout="row wrap" *ngFor="let user of topUsers; let i = index; let lastItem = last;" >
            <div fxLayout="column" fxLayoutAlign="center center">
                <a target="_blank" [href]="'https://learn.omicslogic.com/user/' + user.uid" class="card-container" fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="center center">
                    <img *ngIf="user.avatar; else deafaultImage" [src]="user.avatar" style="border-radius: 50%;" height="40px" width="40px">
                    <ng-template #deafaultImage>
                        <img src="../../../assets/images/avatar.png" style="border-radius: 50%;" height="40px" width="40px">
                    </ng-template>
                    <div style="font-size:12px; color:white" class="overflow-text">{{user.name}}</div>
                    <div [ngStyle]="{'color': getRankColors(i)}" style="font-weight: 700; font-size: 12px; letter-spacing: 1.4px;">RANK {{i+1}}</div>
                    <mat-chip style="background-color: aquamarine; font-weight: 700; font-size: 12px;">{{user.points}} POINTS</mat-chip>
                    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px" style="cursor: default;" matTooltipClass="tooltip-list" 
                    [matTooltip]="['Completed Courses: ' + user.codeCoursesCompleted, 
                    'Completed Lessons: ' + user.codeLessonsCompleted,
                    'Completed Steps: ' + user.codeLessonStepsCompleted,
                    'Completed Codeblocks: ' + user.codeLessonCodeBlocksCompleted
                    ].join('\n')">
                        <div style="font-size: 12px; color:#e0e0e0;">{{user.totalAchievements}} Achievements</div>
                        <mat-icon style="font-size:14px; color:#e0e0e0;">information</mat-icon>
                    </div>
                </a>
            </div>
            <mat-divider fxHide.xs *ngIf="!lastItem" [vertical]="true"></mat-divider>
        </div>


        
    </div>
</div>