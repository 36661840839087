import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs'
import { Achievement } from 'src/app/models/course.model';
import { DatabaseService } from 'src/app/shared/services/database/database.service';
import { SnackService } from 'src/app/shared/services/snack/snack.service';
import { ChartDataSets } from 'chart.js';
import {  Color } from 'ng2-charts';

@Component({
  selector: 'app-recent-activity-card',
  templateUrl: './recent-activity-card.component.html',
  styleUrls: ['./recent-activity-card.component.scss']
})
export class RecentActivityCardComponent implements OnInit, OnDestroy {
  recentAchievements: Achievement[];
  recentAchievementsSub: Subscription;
  pointsChartData: ChartDataSets[] = [];
  pointsChartLabels = [];
   lineChartColors: Color[] = [
    { // red
      backgroundColor: '#1cb3ff80',
      borderColor: 'aquamarine',
      pointBackgroundColor: 'aquamarine',
      pointBorderColor: 'aquamarine',
      pointHoverBackgroundColor: 'aquamarine',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  constructor(private db: DatabaseService, private snack: SnackService) { }

  ngOnInit(): void {
    this.recentAchievementsSub = this.db.getRecentAchievements().subscribe((achievements) =>{
      this.recentAchievements = [];
      achievements.forEach((achievement)=>{this.recentAchievements.push(achievement);});
      this.getPointsList();
      this.getPointsChartLabel();
    }, (error)=>{
      console.log(error)
      this.snack.generalSnack('Error loading recent achievements, try reload the page.', 'Ok');
    });
  }

  ngOnDestroy(): void {
    this.recentAchievementsSub.unsubscribe();
  }

  getPointsList(){
    const points = [];
    this.recentAchievements.forEach((achievement)=>
     points.push(achievement.achievementPoints)
    );
    this.pointsChartData = [{data: points.reverse(), label: 'Points'}]
  }

  getPointsChartLabel(){
    this.recentAchievements.forEach((achievement)=>
    this.pointsChartLabels.push(achievement.timeStamp.toDate().toLocaleString())
   );
   return this.pointsChartLabels.reverse();
  }

}
