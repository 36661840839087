<div *ngIf="db.isEditor(userRole)" fxLayout="row" style="position: fixed; z-index: 1000;">
    <div   fxLayout="column" [ngStyle]="{'width': isHidden ? '0px' : '200px'}" style="height: 100vh;  background-color: #dbdbdb; transition: 0.3s; text-align: center;">
        <div *ngIf="!isHidden">
            <div>
                <div class="profile-container" *ngIf="userProfile | async as user;">
                    <div fxLayout="column" fxLayoutAlign="center center" style="padding: 32px 16px;">
                        <img *ngIf="user.avatar === undefined; else userAvatar" src="url(../../../../../assets/images/avatar.png" alt="Avatar" class="avatar">
                        <ng-template #userAvatar>
                            <img  [src]="user.avatar" alt="Avatar" class="avatar">
                        </ng-template>
                        <h4>{{user.name}}</h4>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div style="padding:16px">
                    <div class="nav-item" [routerLink]="['/dashboard']" [routerLinkActive]="['is-active']" (click)="toggleNav()">Home</div>
                    <div class="nav-item" *ngIf="db.isAdmin(userRole)"  [routerLink]="['/users']" [routerLinkActive]="['is-active']" (click)="toggleNav()">User</div>
                    <button fxFill mat-button [matMenuTriggerFor]="codeMenu">Courses</button>
                    <mat-menu #codeMenu="matMenu">
                        <button mat-menu-item *ngIf="db.isEditor(userRole)"  [routerLink]="['/code']" [routerLinkActive]="['is-active']" (click)="toggleNav()">All Courses</button>
                        <button mat-menu-item *ngIf="db.isEditor(userRole)"  [routerLink]="['/code/my-courses']" [routerLinkActive]="['is-active']" (click)="toggleNav()">My Courses</button>
                        <button mat-menu-item *ngIf="db.isAdmin(userRole)"  [routerLink]="['/code/pages']" [routerLinkActive]="['is-active']" (click)="toggleNav()">Pages</button>
                    </mat-menu>
                    <div class="nav-item" [routerLink]="['/programs']" [routerLinkActive]="['is-active']" (click)="toggleNav()">Programs</div>
                    <div class="nav-item" [routerLink]="['/projects']" [routerLinkActive]="['is-active']" (click)="toggleNav()">Projects</div>
                </div>



            </div>
            <button mat-stroked-button (click)="signOutUser()">Sign Out</button>
        </div>

    </div>
    <div (click)="toggleNav()" style=" height: 40px; background-color: #dbdbdb;" *ngIf="db.isEditor(userRole)">
    <button mat-icon-button title="Menu">
        <mat-icon>menu</mat-icon>
    </button>
    </div>
</div>
<ng-content></ng-content>