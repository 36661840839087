import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../shared/services/auth/auth.service';
import { take, switchMap } from 'rxjs/operators';
import { DatabaseService } from '../../shared/services/database/database.service';
import { SnackService } from '../../shared/services/snack/snack.service';


@Injectable({
  providedIn: 'root'
})
export class AdminLoadGuard implements CanLoad {
  constructor(private auth: AuthService, private db: DatabaseService, private router: Router, private snack: SnackService){}
  
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
      return this.auth.signedInUser.pipe(take(1), 
      switchMap( async (user:any) =>{
        if(user){
          const userRole = await this.db.getUserRole(user.uid);
          if(userRole === "admin"){
            // this.router.navigateByUrl('/dashboard');
            // this.snack.generalSnack('Success!', 'Ok');
            return true;
          } else {
            this.auth.signOutUser();
            this.router.navigate['/'];
            this.snack.generalSnack('Not Authorized!', 'Ok');
            return false;
          }
        } else {
          this.auth.signOutUser();
          this.router.navigate['/'];
          this.snack.generalSnack('Not Authorized!', 'Ok');
          return false;
        }
      })
      )
  }
}
