import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { DatabaseService } from '../../services/database/database.service';

@Component({
  selector: 'app-id-username-span',
  templateUrl: './id-username-span.component.html',
  styleUrls: ['./id-username-span.component.scss']
})
export class IdUsernameSpanComponent implements OnInit {
  @Input() uid: string
  user: Observable<User>

  constructor(private db: DatabaseService) { }

  ngOnInit(): void {
    this.user = this.db.getStudentProfile(this.uid)
  }

}
