<!-- <div [innerHTML]="data"></div>
<button mat-raised-button (click)="cancel()">Cancel</button>
<button mat-raised-button (click)="confirm()">Reject</button> -->

<h1 style="font-weight: 600;" mat-dialog-title>{{data.dialogText}}</h1>
<div mat-dialog-content >
  <p>Send rejection email</p>
  <mat-form-field appearance="fill" style="width:100%">
    <mat-label>{{data.textLabel}}</mat-label>
    <textarea matInput cdkTextareaAutosize [(ngModel)]="data.message"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="cancel()">Cancel</button>
  <button mat-raised-button [disabled]="data.message <= 0" color="warn" [mat-dialog-close]="data.message">Reject</button>
</div>