import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';



export class FileUpload {
  key: string;
  name: string;
  url: string;
  file: File;
 
  constructor(file: File) {
    this.file = file;
  }
}


@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private storage: AngularFireStorage) { }



  async uploadFile(file, path: string){
    let url;
    await this.storage.upload(path, file);
    url = await this.storage.ref(path).getDownloadURL().toPromise();
    return url;
  }


  storageRef(path){
    return this.storage.ref(path);
  }


  deleteSingleFile(downloadUrl: string){
    return this.storage.storage.refFromURL(downloadUrl).delete();
  }
  




}
