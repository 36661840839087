<div>
    <input
      matInput
      
      [matAutocomplete]="auto"
      [placeholder]="placeholder"
      (keyup)="handleChange($event)"
      style="width: 100%; background-color: #f2f2f2; border: none; padding: 10px; font-family: 'Montserrat'; font-size: 16px; height: 32px;"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      style="margin-top: 30px; max-height: 600px"
    >
      <div *ngFor="let index of state.indices || []">
        <mat-option
        style="height:100%; line-height: normal;"
          *ngFor="let option of index.hits"
          [value]="option.uid"
          (click)="onQuerySuggestionClick.emit({ query: option.uid })"
        >
          <div fxLayout="row" fxLayout="start center" fxLayoutGap="16px" style="padding: 16px 0px;">
              <strong>{{ option.name }}</strong>
              <span >{{ option.email }}</span>
              <span >{{ option.uid }}</span>
          </div>
        </mat-option>
      </div>
    </mat-autocomplete>
  </div>