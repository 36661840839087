import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UploadedFile } from 'src/app/models/files.model';
import { DatabaseService } from '../services/database/database.service';

@Component({
  selector: 'uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit, OnDestroy {
  @Input() uploadPath: string;
  @Input() fileCollectionPath: string;
  isHovering: boolean;

  files: File[] = [];

  uploadedFiles: UploadedFile[];
  uploadedFilesSub: Subscription;

  constructor(private db: DatabaseService){}

  ngOnInit() {
    this.uploadedFilesSub = this.db.getUploadedFiles(this.fileCollectionPath).subscribe((files)=>{
      this.uploadedFiles = files;
    });
  }

  ngOnDestroy(){
    this.uploadedFilesSub.unsubscribe();
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.files.push(files.item(i));
    }
  }
}