import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UploadedFile } from 'src/app/models/files.model';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { DatabaseService } from '../services/database/database.service';
import { SnackService } from '../services/snack/snack.service';

@Component({
  selector: 'app-uploaded-file',
  templateUrl: './uploaded-file.component.html',
  styleUrls: ['./uploaded-file.component.scss']
})
export class UploadedFileComponent implements OnInit {
  @Input() uploadedFile: UploadedFile;


  constructor(private db: DatabaseService, private snack: SnackService, public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openDeleteDialog() {
    const deleteDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        dialogText: "Are you sure you want to delete this file?"
      }
    });

    deleteDialog.afterClosed().subscribe(result => {
      if(result){
        this.deleteFile();
      }
    });
  }

  async deleteFile(){
    try {
      await this.db.deleteUploadedFile(this.uploadedFile.ref, this.uploadedFile.downloadURL)
    } catch (error) {
      this.snack.generalSnack(error, 'Ok');
    }
  }

}
