<h1 mat-dialog-title>{{data.title}} <a mat-icon-button href="{{currentUrl}}/programs" target="_blank"> <mat-icon>open_in_new</mat-icon></a></h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>{{data.inputLabel}}</mat-label>
    <input matInput [(ngModel)]="data.programId">
  </mat-form-field>

  <div *ngIf="data.addSelect">
    <mat-form-field >
        <mat-label>{{data.selectLabel}}</mat-label>
        <mat-select [(ngModel)]="data.accessType">
          <mat-option *ngFor="let option of data.selectOptions;" [value]="option.value" >
            {{option.display}}
          </mat-option>
        </mat-select>
      </mat-form-field>
  </div>
 
  
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="{programId: data.programId, accessType: data.accessType}" cdkFocusInitial>Ok</button>
</div>