import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Course } from '../../models/course.model';
import { DatabaseService } from 'src/app/shared/services/database/database.service'

@Component({
  selector: 'app-top-courses-card',
  templateUrl: './top-courses-card.component.html',
  styleUrls: ['./top-courses-card.component.scss'],
})
export class TopCoursesCardComponent implements OnInit {
  topCourses: Observable<Course[]>
  constructor(private db: DatabaseService) { }

  ngOnInit(): void {
    this.topCourses = this.db.getTopCourses();
  }

}
