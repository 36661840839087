import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from '../../../models/user.model';
import { Observable } from 'rxjs/internal/Observable';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

 

  constructor(private afAuth: AngularFireAuth) { 

  }

  signInUserEmail(email: string, password: string){
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  signOutUser(){
    return this.afAuth.signOut()
  }

  get currentUser(){
    return this.afAuth.currentUser;
  }


  get signedInUser(){
    return this.afAuth.authState;
  }

}
