import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../shared/services/auth/auth.service'
import { DatabaseService } from '../shared/services/database/database.service'
import { SnackService } from '../shared/services/snack/snack.service'
import { Router } from '@angular/router';


@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit{
  loginForm: FormGroup;
  isLoading: boolean = false;
  constructor(private fb: FormBuilder, private auth: AuthService, private router: Router, private db: DatabaseService, private snack: SnackService) { }




  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      })
  }

  async onSubmit() {
    if(this.loginForm.invalid){
      return;
    }
    this.isLoading = true;
    const email = this.loginForm.value.email;
    const password = this.loginForm.value.password;
    try{
      let user = await this.auth.signInUserEmail(email, password);
      if(user){
        this.router.navigate(['/dashboard'])
      }
    } catch(err){
      this.auth.signOutUser();
      this.snack.generalSnack(err, 'Ok');
    }
    this.isLoading = false;
  }

}
