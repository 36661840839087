import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { AuthService } from '../auth/auth.service';
import { map, switchMap } from 'rxjs/internal/operators';
import { StripeUser, User, UserRole } from '../../../models/user.model'
import { Achievement, Course, CourseProgress, Lesson, LessonProgress } from '../../../models/course.model'
import firebase from 'firebase/app'; 
import 'firebase/firestore';
import { UploadService } from '../upload/upload.service';
import { Observable, of } from 'rxjs';
import { UploadedFile } from 'src/app/models/files.model';
import { CustomPage } from 'src/app/models/page.model';
import { CourseStatistics, SimilarUsers, UserCourseRecommendation, UserLanguages, UserLessonRecommendation, UserStudySpeed } from 'src/app/models/analytics.model';
import slugify from 'slugify';
import { Program, SessionInfo, UserProgramDetails } from 'src/app/models/program.model';
import { Color } from '@angular-material-components/color-picker';
import moment from 'moment';
import { Project } from 'src/app/models/project.model';


@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  userRole$: Observable<UserRole>;
  constructor(private auth: AuthService, private db: AngularFirestore,  private storage: UploadService) {
    this.userRole$ = this.auth.signedInUser.pipe(
      switchMap(user => {
        if (user) {
          return this.db
            .collection('Users').doc(user.uid).collection('Private').doc<UserRole>('Roles')
            .valueChanges();
        } else {
          return of(null)
        }
      })
    );
   }


  //  User

  getUserProfile() {
    return this.auth.signedInUser.pipe(
      switchMap(user => {
        if (user) {
          return this.db
            .collection('Users').doc<User>(user.uid)
            .valueChanges();
        }
      })
    );
  }



   async getUserRole(uid: string) {
     let role: string;
    await this.db.collection("Users").doc(uid).collection('Private').doc('Roles').ref.get().then((doc)=> {
      if(doc.data() === undefined){
        role = 'user';
      } else {
        role = doc.data().role;
      }
      })
      return role;
    }



    isEditor(user: UserRole) :boolean {
      const allowed = ['admin', 'editor']
      return this.checkAuthorization(user, allowed)
    }

    isAdmin(user: UserRole) : boolean {
      const allowed = ['admin']
      return this.checkAuthorization(user, allowed)
    }



      // determines if user has matching role
  private checkAuthorization(userRole: UserRole, allowedRoles: string[]): boolean {
    if (!userRole) return false;
    if ( allowedRoles.includes(userRole.role) ) {
        return true
    }else return false
  }



    // files and images storage
    async deleteUploadedFile(fileDocRef: DocumentReference, fileDownloadUrl){
      await fileDocRef.delete();
      return this.storage.deleteSingleFile(fileDownloadUrl);
    }

    updateCoverImage(ref: DocumentReference, url: string){
      return ref.update({coverUrl: url});
    }

    updateCertificateUrl(ref: DocumentReference, url: string){
      return ref.update({certificateUrl: url});
    }

    updateTextCoverImage(ref: DocumentReference, url: string){
      return ref.update({textCoverUrl: url});
    }


    updateBadgeImage(ref: DocumentReference, url: string){
      return ref.update({badgeUrl: url});
    }


    
  
    getDbRefFromPath(path: string){
      return this.db.doc(path).ref;
    }

    getUploadedFiles(fileCollectionPath: string){
      return this.db.collection(fileCollectionPath, ref => ref.orderBy('fileName', 'asc').limit(20)).snapshotChanges().pipe(
        map(actions => actions.map(a=> {
          const data = a.payload.doc.data() as UploadedFile;
          const ref = a.payload.doc.ref;
          return {ref, ...data}
        }))
      );
    }

    // programs
    async createProgram(data){
      const user = await this.auth.currentUser
      const slug = slugify(data.programTitle, {strict: true, lower:true})
      let fixedData = {
        ...data
      }

      fixedData.primaryColor = data.primaryColor.toRgba();
      fixedData.secondaryColor = data.secondaryColor.toRgba();

      const startYear = (data.programStartDate as any).getFullYear()
      const startDate = (data.programStartDate as any).getDate()
      const startMonth = (data.programStartDate as any).getMonth()
      const programStartDate = new Date(Date.UTC(startYear,startMonth,startDate,11));

      const endYear = (data.programEndDate as any).getFullYear()
      const endDate = (data.programEndDate as any).getDate()
      const endMonth = (data.programEndDate as any).getMonth()
      const programEndDate = new Date(Date.UTC(endYear,endMonth,endDate,11));


      
 

      fixedData.programStartDate = programStartDate as any
      fixedData.programEndDate = programEndDate as any


      data.sessions.forEach((session: SessionInfo) =>{
        const startYear = (session.sessionDate as any).getFullYear()
        const startDate = (session.sessionDate as any).getDate()
        const startMonth = (session.sessionDate as any).getMonth()
        const sessionStartDate = new Date(Date.UTC(startYear,startMonth,startDate,11));
        session.sessionDate = sessionStartDate as any
      })



      return this.db.collection<Program>('Programs').add({
        ...fixedData,
        slug,
        author: user.uid,
        isPublished: false,
        isReleased: false,
        timesCompleted: 0,
        created: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }


    async addBadge(image: File, path: string, ref){
      return await this.storage.uploadFile(image, path).then((url) =>{
        this.updateBadgeImage(ref, url)
      })
    }


    getInitialPrograms(){
      return this.db.collection<Program>('Programs', ref => ref.orderBy('programTitle', 'asc')).valueChanges({ idField: 'programId' });
    }

    getNextPrograms(last: Program){
      return this.db.collection<Program>('Programs', ref => ref.orderBy('programTitle', 'asc').startAfter(last.programTitle).limit(5)).valueChanges({idField: 'programId'});
    }

    updateProgramIsPublished(programId: string, isPublished: boolean){
      return this.db.collection('Programs').doc(programId).update({
        isPublished: isPublished
      });
    }

    updateProgramIsReleased(programId: string, isReleased: boolean){
      return this.db.collection('Programs').doc(programId).update({
        isReleased: isReleased
      });
    }

    getProgram(programId: string){
      return this.db.collection('Programs').doc<Program>(programId).valueChanges();
    }

    getProgramOnce(programId: string){
      return this.db.collection('Programs').doc<Program>(programId).get();
    }

    getProgramCoupons(programId: string){
      return this.db.collection('Programs').doc(programId).collection('Coupons').doc('StripeCoupons').valueChanges();
    }


    updateProgram(programId: string, data: Program){
      const slug = slugify(data.programTitle, {strict: true, lower:true});
      let fixedData = {
        ...data
      }

      if(data.primaryColor instanceof Color){
        fixedData.primaryColor = data.primaryColor.toRgba();
      }
      if(data.secondaryColor instanceof Color){
        fixedData.secondaryColor = data.secondaryColor.toRgba();
      }


      // Avoide time random changes
      const startYear = (data.programStartDate as any).getFullYear()
      const startDate = (data.programStartDate as any).getDate()
      const startMonth = (data.programStartDate as any).getMonth()
      const programStartDate = new Date(Date.UTC(startYear,startMonth,startDate,11));

      const endYear = (data.programEndDate as any).getFullYear()
      const endDate = (data.programEndDate as any).getDate()
      const endMonth = (data.programEndDate as any).getMonth()
      const programEndDate = new Date(Date.UTC(endYear,endMonth,endDate,11));

 

      fixedData.programStartDate = programStartDate as any
      fixedData.programEndDate = programEndDate as any


      data.sessions.forEach((session: SessionInfo) =>{
        const startYear = (session.sessionDate as any).getFullYear()
        const startDate = (session.sessionDate as any).getDate()
        const startMonth = (session.sessionDate as any).getMonth()
        const sessionStartDate = new Date(Date.UTC(startYear,startMonth,startDate, 11));
        session.sessionDate = sessionStartDate as any
      })




      return this.db.collection('Programs').doc(programId).update({
        ...fixedData,
        slug,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      })
    }


     createDateAsUTC(date) {
      return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
  }
  
   convertDateToUTC(date) { 
      return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); 
  }

    getUserProgramProgress(userId:string, programId: string){
      return this.db.doc<UserProgramDetails>(`Users/${userId}/ProgramProgress/${programId}`).get()
    }

    getAllUserProgramProgress(userId:string){
      return this.db.collection<UserProgramDetails>(`Users/${userId}/ProgramProgress`).valueChanges()
    }

    async addProgramToUser(userId: string, userEmail:string ,programId: string, program: Program, accessType: string){
;
      const defaultEndDate = program.programEndDate;
      const programStartDate = program.programStartDate;
      const extraMonth = moment.duration(1, 'months');
      const momentEndDate = moment(defaultEndDate.toDate());
      const advancedEndDate = momentEndDate.add(extraMonth)


        const batch = this.db.firestore.batch();

        // Add program to user
        batch.set(this.db.doc(`Users/${userId}/ProgramProgress/${programId}`).ref, {
            programId,
            joinedDate: firebase.firestore.FieldValue.serverTimestamp(),
            accessType,
            startDate: programStartDate,
            endDate: accessType === 'advanced' ? advancedEndDate.toDate() : defaultEndDate,
        });

        // Update program members count
        batch.update(this.db.doc(`Programs/${programId}`).ref, { 
          members: firebase.firestore.FieldValue.increment(1)
        });

      // update user's programs count
      batch.update(this.db.doc(`Users/${userId}`).ref, {
          programsJoined: firebase.firestore.FieldValue.increment(1)
        });

        //check for admin here and skip tbio tasks

        const adminsList = [
          'xU7N3yHuU7hqRO5W58kbu2kNIKw1', // test@test.com
          'abQCt29LBXUXoiyRYegy3Prlgpi1', //elia
          'q67tE3nfUza02hkB6MTZo1EQJnn1', //marketing
          'FUtz9baa9aVOp7MWi4EIKH3dHBI2', //support
        ]
        if(!adminsList.includes(userId)){

                //schedule tbio role upgrade task
          batch.set(this.db.collection('Tasks').doc().ref, {
            options: {
                email: userEmail,
                role: accessType === 'advanced' ? 9 : 8
            },
            performAt: programStartDate,
            status: 'scheduled',
            worker: 'updateTBioUserRole'
        });

        //schedule tbio role downgrade task
        batch.set(this.db.collection('Tasks').doc().ref, {
            options: {
                email: userEmail,
                role: 7
            },
            performAt: accessType === 'advanced' ? advancedEndDate.toDate() : defaultEndDate,
            status: 'scheduled',
            worker: 'updateTBioUserRole'
        });

        }


      return await batch.commit();
    }
    

    extendProgramAccess(userId: string, programId: string, endDate: Date){
      return this.db.doc(`Users/${userId}/ProgramProgress/${programId}`).update({
        endDate
      })
    }

    

    // courses

    async createCourse(data: Course){
      const user = await this.auth.currentUser
      const slug = slugify(data.courseTitle, {strict: true, lower:true})
      return this.db.collection('CodeCourses').add({
        ...data,
        slug,
        author: user.uid,
        isPublished: false,
        isReleased: false,
        lessonsCount: 0,
        timesCompleted: 0,
        created: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }

    async addCover(image: File, path: string, courseRef){
      return await this.storage.uploadFile(image, path).then((url) =>{
        this.updateCoverImage(courseRef, url)
      })
    }

    async addTextCover(image: File, path: string, courseRef){
      return await this.storage.uploadFile(image, path).then((url) =>{
        this.updateTextCoverImage(courseRef, url)
      })
    }

    async addCertificate(certPdfFile: File, path: string, courseRef){
      return await this.storage.uploadFile(certPdfFile, path).then((url) =>{
        // console.log(certPdfFile)
        // console.log(url)
        this.updateCertificateUrl(courseRef, url)
      })
    }


    updateCourse(courseId: string, data: Course){
      const slug = slugify(data.courseTitle, {strict: true, lower:true})
      console.log(slug)
      return this.db.collection('CodeCourses').doc(courseId).update({
        ...data,
        slug,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      })
    }

    async deleteCourse(course: Course){
      if(course.coverUrl){
        await this.storage.deleteSingleFile(course.coverUrl);
      } 
      if(course.textCoverUrl) {
        await this.storage.deleteSingleFile(course.textCoverUrl);
      } 
      if(course.certificateUrl) {
        await this.storage.deleteSingleFile(course.certificateUrl);
      } 
      return await this.db.collection('CodeCourses').doc(course.courseId).delete();
    }

    updateCourseIsPublished(courseId: string, isPublished: boolean){
      return this.db.collection('CodeCourses').doc(courseId).update({
        isPublished: isPublished
      });
    }

    updateCourseIsReleased(courseId: string, isReleased: boolean){
      return this.db.collection('CodeCourses').doc(courseId).update({
        isReleased: isReleased
      });
    }




    // getInitialCourses(){
    //   return this.db.collection<Course>('CodeCourses', ref => ref.orderBy('courseTitle', 'asc').limit(10)).valueChanges({ idField: 'courseId' });
    // }

    getInitialCourses(){
      return this.db.collection<Course>('CodeCourses', ref => ref.orderBy('courseTitle', 'asc')).valueChanges({ idField: 'courseId' });
    }

    getNextCourses(last: Course){
      return this.db.collection<Course>('CodeCourses', ref => ref.orderBy('courseTitle', 'asc').startAfter(last.courseTitle).limit(5)).valueChanges({idField: 'courseId'});
    }

    getMyCourses(){
      return this.auth.signedInUser.pipe(
        switchMap(user => {
          if (user) {
            return this.db.collection<Course>('CodeCourses', ref => ref.where('author', '==', user.uid).orderBy('courseTitle', 'asc')).valueChanges({ idField: 'courseId' });
          }
        })
      );
    }

    getMyNextCourses(last: Course){
      return this.auth.signedInUser.pipe(
        switchMap(user => {
          if (user) {
            return this.db.collection<Course>('CodeCourses', ref => ref.where('author', '==', user.uid).orderBy('courseTitle', 'asc').startAfter(last.courseTitle).limit(5)).valueChanges({ idField: 'courseId' });
          }
        })
      );
    }


    getCourse(courseId: string){
      return this.db.collection('CodeCourses').doc<Course>(courseId).valueChanges();
    }

    getCourseStatistics(courseId: string){
      return this.db.collection('Analysis').doc('CourseAnalytics').collection('CourseStatistics').doc<CourseStatistics>(courseId).valueChanges();
    }

    // lessons

    getInitialLessons(courseId: string){
      return this.db.collection<Lesson>(`CodeCourses/${courseId}/Lessons`, ref => ref.orderBy('lessonTitle', 'asc')).valueChanges({ idField: 'lessonId' });
    }

    getNextLessons(courseId: string, last: Lesson){
      return this.db.collection<Lesson>(`CodeCourses/${courseId}/Lessons`, ref => ref.orderBy('lessonTitle', 'asc').startAfter(last.lessonTitle).limit(5)).valueChanges({idField: 'lessonId'});
    }

    getLesson(courseId: string, lessonId: string){
      return this.db.collection('CodeCourses').doc(courseId).collection('Lessons').doc<Lesson>(lessonId).valueChanges();
    }

    getLessonStatistics(courseId: string, lessonId: string){
      return this.db.doc(`Analysis/CourseAnalytics/CourseStatistics/${courseId}/LessonStatistics/${lessonId}`).valueChanges();
    }



    async createLesson(courseId: string, data: Lesson){
      const user = await this.auth.currentUser
      const slug = slugify(data.lessonTitle, {strict: true, lower:true})
      return this.db.collection(`CodeCourses/${courseId}/Lessons`).add({
        ...data,
        author: user.uid,
        slug,
        isPublished: false,
        isReleased: false,
        timesCompleted: 0,
        created: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }


    async addLessonCover(image: File, path: string, lessonRef){
      return await this.storage.uploadFile(image,path).then((url)=>{
        this.updateCoverImage(lessonRef, url)
      })
    }

    async deleteLesson(lesson: Lesson, courseId: string){
      if(lesson.coverUrl){
        await this.db.collection('CodeCourses').doc(courseId).collection('Lessons').doc(lesson.lessonId).delete();
        return this.storage.deleteSingleFile(lesson.coverUrl);
      } else return await this.db.collection('CodeCourses').doc(courseId).collection('Lessons').doc(lesson.lessonId).delete();
    }


    updateLessonIsPublished(courseId: string, lessonId: string,isPublished: boolean){
      return this.db.collection('CodeCourses').doc(courseId).collection('Lessons').doc(lessonId).update({
        isPublished: isPublished
      });
    }

    updateLessonIsReleased(courseId: string, lessonId: string,isReleased: boolean){
      return this.db.collection('CodeCourses').doc(courseId).collection('Lessons').doc(lessonId).update({
        isReleased: isReleased
      });
    }

    updateLesson(courseId: string, lessonId, data: Lesson){
      const slug = slugify(data.lessonTitle, {strict: true, lower:true})
      return this.db.collection('CodeCourses').doc(courseId).collection('Lessons').doc(lessonId).update({
        ...data,
        slug,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      })
    }



    // custom code pages

    getCustomCodePages(){
      return this.db.collection<CustomPage>('CodePages', ref => ref.orderBy('pageTitle', 'asc')).valueChanges({ idField: 'pageId' });
    }

    getCustomCodePage(pageId: string){
      return this.db.collection('CodePages').doc<CustomPage>(pageId).valueChanges();
    }

    async createNewCustomCodePage(data: CustomPage){
      const user = await this.auth.currentUser
      let pageUrl = data.pageTitle.replace(/\W+(?!$)/g, '-').toLowerCase();
      pageUrl = pageUrl.replace(/\W$/, '').toLowerCase();
      return this.db.collection('CodePages').doc(pageUrl).set({
        ...data,
        author: user.uid,
        isPublished: false,
        isReleased: false,
        created: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }

    updateCustomCodePage(pageId: string, data: CustomPage){
      return this.db.collection('CodePages').doc(pageId).update({
        ...data
      });
    }

    updateCustomCodePageIsPublished(pageId: string,isPublished: boolean){
      return this.db.collection('CodePages').doc(pageId).update({
        isPublished: isPublished
      });
    }

    async deleteCustomCodePage(page: CustomPage){
      return await this.db.collection('CodePages').doc(page.pageId).delete();
    }




    // dashboard functions

    getRecentAchievements(){
      return this.db.collectionGroup<Achievement>('Achievements', ref => ref.where('platform', '==', 'OmicsLogic Code').orderBy('timeStamp', 'desc').limit(10)).valueChanges();
    }

    getStudentProfile(userId: string){
      return this.db
      .collection('Users').doc<User>(userId)
      .valueChanges();
    }

    getTopLessons(){
      return this.db.collectionGroup<Lesson>('Lessons', ref => ref.orderBy('timesCompleted', 'desc').limit(5)).valueChanges();
    }

    getTopCourses(){
      return this.db.collection<Course>('CodeCourses', ref => ref.where('isReleased' , '==', true).orderBy('timesCompleted', 'desc').limit(5)).valueChanges({ idField: 'courseId' });
    }

    getTopUsers(limit: number){
      return this.db.collection<User>('Users', ref => ref.orderBy('points', 'desc').limit(limit)).valueChanges();
    }


    //User module functions

    getUserEmail(userId: string){
      return this.db.collection('Users').doc(userId).collection('Private').doc('userDetails').valueChanges();
    }

    getUserProfileRole(userId: string){
      return this.db.collection('Users').doc(userId).collection('Private').doc<UserRole>('Roles').valueChanges();
    }

    updateUserProfileRole(userId: string, role: string){
      return this.db.collection('Users').doc(userId).collection('Private').doc<UserRole>('Roles').update({
        role
      });
    }

    getStripeUser(userId: string){
      return this.db.collection('StripeUsers').doc<StripeUser>(userId).valueChanges();
    }

    getUserCourseProgress(userId: string){
      return this.db.collection('Users').doc(userId).collection<CourseProgress>('CodeLessonsProgress').valueChanges({ idField: 'courseId'});
    }

    // getUserCourseProgressO(userId: string){
    //   return this.db.collection('Users').doc(userId).collection<CourseProgress>('CodeLessonsProgress', ref => ref.orderBy('courseStart', 'desc')).valueChanges({ idField: 'courseId'});
    // }

    

    getUserLessonProgress(userId: string, courseId: string){
      return this.db.collection('Users').doc(userId).collection('CodeLessonsProgress').doc(courseId).collection<LessonProgress>('Lessons').valueChanges({ idField: 'lessonId'});
    }

    getUserCourseRecommendations(userId: string){
      return this.db.doc<UserCourseRecommendation>(`Analysis/Recommendations/RecommendedCourses/${userId}`).valueChanges()
    }

    getUserLessonRecommendations(userId: string){
      return this.db.doc<UserLessonRecommendation>(`Analysis/Recommendations/RecommendedLessons/${userId}`).valueChanges()
    }

    getUserStudySpeed(userId:string){
      return this.db.doc<UserStudySpeed>(`Analysis/UserAnalytics/StudySpeed/${userId}`).valueChanges()
    }

    getUserLanguageSelection(userId:string){
      return this.db.doc<UserLanguages>(`Analysis/UserAnalytics/LanguageSelection/${userId}`).valueChanges()
    }

    getUserSimilarUsers(userId:string){
      return this.db.doc<SimilarUsers>(`Analysis/UserAnalytics/SimilarUsers/${userId}`).valueChanges()
    }


    // Projects Functions

    cancelProjectReview(project:Project, feedback: string){
      return this.db.doc(`Users/${project.author}/UserProjects/${project.projectId}`).update({
        inReview: false,
        feedback,
        submittedDate: firebase.firestore.FieldValue.delete(),
      })
    }

    acceptProjectReview(project: Project){
      return this.db.doc(`Users/${project.author}/UserProjects/${project.projectId}`).update({
        inReview: false,
        passedReview: true,
        feedback: firebase.firestore.FieldValue.delete(),
        submittedDate: firebase.firestore.FieldValue.delete(),
        reviewedDate: firebase.firestore.FieldValue.serverTimestamp(),
      })
    }

    uncompleteProject(project: Project){
      return this.db.doc(`Users/${project.author}/UserProjects/${project.projectId}`).update({
        inReview: false,
        passedReview: false,
        submittedDate: firebase.firestore.FieldValue.delete(),
        reviewedDate: firebase.firestore.FieldValue.delete(),
      })
    }

    getInitalUserProjects(){
      return this.db.collectionGroup('UserProjects', ref => ref.orderBy('created', 'desc').limit(10)).valueChanges();
    }
  
    getNextUserProjects(last: Project){
      return this.db.collectionGroup('UserProjects', ref => ref.orderBy('created', 'desc').startAfter(last.created).limit(10)).valueChanges();    
    }

    getPreviousUserProjects(first: Project){
      return this.db.collectionGroup('UserProjects', ref => ref.orderBy('created', 'desc').endBefore(first.created).limit(10)).valueChanges();    
    }

}
