<div class="card-back" fxLayout="column" style="text-align:center">
    <h4>Recent activity:</h4>
    <div fxLayout="row wrap" fxLayoutAlign="space-around center">
        <div class="activity-container" fxFlex="45" >
            <app-user-activity-tile *ngFor="let achievement of recentAchievements" [achievement]="achievement"></app-user-activity-tile>
    </div>
    <div fxFlex="40" >
        <canvas *ngIf="pointsChartData?.length !== 0" baseChart
        [datasets]="pointsChartData"
        [labels]="pointsChartLabels"
        [legend]="true"
        [colors]="lineChartColors"
        chartType="line"></canvas>
    </div>
    </div>
</div>