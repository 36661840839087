<div fxLayout="row wrap" fxLayoutAlign="space-between center" style="padding:8px" fxLayoutGap="16px">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">

        <div style="background-color: aquamarine; border-radius: 50%; height: 40px; width: 40px; text-align: center; line-height: 40px;">
            <h5 style="margin:0">{{achievement.achievementPoints}}</h5>
        </div>
    
        <a *ngIf="userProfile | async as userProfile" style="cursor: pointer; color:black;" fxLayout="row"  fxLayoutGap="8px" fxLayoutAlign="center center" target="_blank" [href]="'https://learn.omicslogic.com/user/' + achievement.uid ">
            <img *ngIf="userProfile.avatar; else deafaultImage" [src]="userProfile.avatar" style="border-radius: 50%;" height="30px" width="30px">
            <ng-template #deafaultImage>
                <img src="../../../assets/images/avatar.png" style="border-radius: 50%;" height="30px" width="30px">
            </ng-template>
            <div>{{userProfile.name}}</div>
        </a>
    
    </div>


    <a *ngIf="achievement.courseId; else programLinkBlock" style="color: black; font-size:12px" target="_blank" [href]="'https://learn.omicslogic.com/courses/course/' + achievement.courseId">{{achievement.achievementTitle}}</a>
    
    <ng-template #programLinkBlock>
        <a  style="color: black; font-size:12px" target="_blank" [href]="'https://learn.omicslogic.com/programs/' + achievement.programId">{{achievement.achievementTitle}}</a>
    </ng-template>
    
    <div style="font-size:12px">{{achievement.timeStamp.toDate() | date:'short'}}</div>

</div>
<mat-divider></mat-divider>