<div fxLayout="row" style="width: 94vw;">
    <div fxFlex="50" id="bgImage"></div>
    <div fxFlex="50" fxLayout="column" fxLayoutAlign="center center">
        <mat-card>
            <mat-card-header>
                <mat-card-title>OmicsLogic Code</mat-card-title>
                <mat-card-subtitle>Admin Application Sign In</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content >
                <form fxLayout="column" [formGroup]="loginForm" fxLayoutGap="16px">
                    <mat-form-field appearance="fill" fxFlex="5">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email" required>
                        <mat-error *ngIf="!loginForm.controls['email'].valid">Enter valid email!</mat-error>
                      </mat-form-field>

                      <mat-form-field appearance="fill" fxFlex="5">
                        <mat-label>Password</mat-label>
                        <input matInput type="password" formControlName="password" required>
                        <mat-error *ngIf="!loginForm.controls['password'].valid">Enter valid password!</mat-error>
                      </mat-form-field>
                </form>
                <button mat-raised-button color="primary" btnType="submit" type="submit" (click)="onSubmit()" [disabled]="loginForm.invalid ||isLoading">Sign In</button>
            </mat-card-content>
        </mat-card>
    </div>
</div>