import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../services/auth/auth.service';

@Component({
  selector: 'upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss']
})
export class UploadTaskComponent implements OnInit {

  @Input() file: File;
  @Input() uploadPath: string;
  @Input() fileCollectionPath: string;

  task: AngularFireUploadTask;

  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: string;
  shortLink;

  constructor(private storage: AngularFireStorage, private db: AngularFirestore, private http: HttpClient, private auth:AuthService) { }

  ngOnInit() {
    this.startUpload();
  }

  async startUpload() {

    // The storage path
    const path = `${this.uploadPath}/${Date.now()}_${this.file.name}`;

    // Reference to storage bucket
    const ref = this.storage.ref(path);

    try {
          // The main task
    this.task = this.storage.upload(path, this.file);
    const user = await this.auth.currentUser;
    // Progress monitoring
    this.percentage = this.task.percentageChanges();

    this.snapshot   = this.task.snapshotChanges().pipe(
      tap(console.log),
      // The file's download URL
      finalize( async() =>  {
        this.downloadURL = await ref.getDownloadURL().toPromise();
        const dynamicApiLink = "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAy2tui2hud4rhYQytWa_X9TZIFN4eqlXc"
        this.shortLink = await this.http.post(dynamicApiLink, {
          dynamicLinkInfo: {
            domainUriPrefix: 'https://code.omicslogic.com/resources',
            link: this.downloadURL,
            socialMetaTagInfo: {
              socialTitle: this.file.name,
              socialDescription: "OmicsLogic Resource.",
              socialImageLink: "https://code.omicslogic.com/assets/images/meta-image.jpg"
            }
          },
          suffix: {option: 'SHORT'}
        }, {headers : new HttpHeaders({ 'Content-Type': 'application/json' })}).toPromise();
        console.log(this.shortLink)
        this.db.collection(this.fileCollectionPath).add( { downloadURL: this.downloadURL, path, fileName: this.file.name, shortLink: this.shortLink, author: user.uid });
      }),
    );
    } catch (error) {
      console.log(`Error adding file ${this.file.name}: ${error}`)
    }

  }

  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

}