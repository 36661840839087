import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Observable, Subscription } from 'rxjs';
import { User, UserRole } from 'src/app/models/user.model';
import { DatabaseService } from '../../services/database/database.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy {
  userProfile: Observable<User>;
  userRole: UserRole;
  userRoleSub: Subscription;

  constructor(public auth: AuthService, public db: DatabaseService, private router: Router) { }
  isHidden: boolean = true;
  ngOnInit(): void {
    this.userProfile = this.db.getUserProfile();
    this.userRoleSub = this.db.userRole$.subscribe(userRole => this.userRole = userRole);
  }

  ngOnDestroy(): void {
    this.userRoleSub.unsubscribe();
  }

  toggleNav(){
    this.isHidden = !this.isHidden;
  }

  async signOutUser(){
    await this.auth.signOutUser();
    return this.router.navigateByUrl("/");
  }

}
