import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';


//material
import { MatInputModule } from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatStepperModule} from '@angular/material/stepper';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDividerModule} from '@angular/material/divider';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';


import { QuillModule } from 'ngx-quill'
import { ChartsModule } from 'ng2-charts';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { SideNavComponent } from './components/side-nav/side-nav.component';
import { RouterModule } from '@angular/router';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { UploaderComponent } from './uploader/uploader.component';
import { UploadTaskComponent } from './upload-task/upload-task.component';
import { DropzoneDirective } from '../directives/dropzone.directive';
import { UploadedFileComponent } from './uploaded-file/uploaded-file.component';
import { HttpClientModule } from '@angular/common/http';
import { MarkdownBlockComponent } from './markdown-block/markdown-block.component';
import { SafePipe } from './pipes/safe.pipe';
import { EmbedDialogComponent } from './components/embed-dialog/embed-dialog.component';
import { QuillBlockComponent } from './components/quill-block/quill-block.component';
import { PageQuillBlockComponent } from './components/page-quill-block/page-quill-block.component';
import { UserActivityTileComponent } from './components/user-activity-tile/user-activity-tile.component';
import { UserCertificateComponent } from './components/user-certificate/user-certificate.component';
import { TextFieldDialogComponent } from './components/text-field-dialog/text-field-dialog.component';
import { AutocompleteSearchComponent } from './components/autocomplete-search/autocomplete-search.component';
import { IdUsernameSpanComponent } from './components/id-username-span/id-username-span.component';
import { SendEmailDialogComponent } from './components/send-email-dialog/send-email-dialog.component';
import { ScrollableDirective } from './directives/scrollable/scrollable.directive';


const components = [
  SideNavComponent,
  UploaderComponent, 
  UploadTaskComponent,
  MarkdownBlockComponent,
  QuillBlockComponent,
  PageQuillBlockComponent,
  UserActivityTileComponent,
  UserCertificateComponent,
  TextFieldDialogComponent,
  AutocompleteSearchComponent,
  IdUsernameSpanComponent
]

const modules = [
  CommonModule,
  RouterModule,
  FlexLayoutModule,
  ReactiveFormsModule,
  CodemirrorModule,
  HttpClientModule,
  MatInputModule,
  MatSelectModule,
  MatButtonModule,
  MatCardModule,
  MatSnackBarModule,
  MatDialogModule,
  MatChipsModule,
  MatIconModule,
  MatTooltipModule,
  MatExpansionModule,
  MatStepperModule,
  MatSlideToggleModule,
  MatAutocompleteModule,
  FormsModule,
  MatDividerModule,
  ClipboardModule,
  MatMenuModule,
  ChartsModule,
  MatProgressBarModule,
  MatDatepickerModule,
  MatNativeDateModule,
  NgxMatColorPickerModule,
  PdfViewerModule,
  MatTableModule,
  MatPaginatorModule
]



@NgModule({
  declarations: [SideNavComponent, ConfirmDialogComponent, UploaderComponent, UploadTaskComponent, DropzoneDirective, 
    UploadedFileComponent, 
    MarkdownBlockComponent, SafePipe, 
    EmbedDialogComponent, QuillBlockComponent, 
    PageQuillBlockComponent, UserActivityTileComponent, UserCertificateComponent, TextFieldDialogComponent, AutocompleteSearchComponent, IdUsernameSpanComponent, SendEmailDialogComponent, ScrollableDirective],
  imports: [
    ...modules,
    QuillModule.forRoot({})
  ],
  exports:[
    ...modules,
    ...components,
    DropzoneDirective,
    SafePipe,
    QuillModule,
    ScrollableDirective
  ],
  providers:[
    { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }
  ]
})
export class SharedModule { }
