import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import * as Quill from 'quill';
import BlotFormatter from 'quill-blot-formatter/dist/BlotFormatter';
import { QuillModules } from 'ngx-quill';
import { htmlEditButton } from 'quill-html-edit-button'
import { EmbedDialogComponent } from 'src/app/shared/components/embed-dialog/embed-dialog.component';
import { FormGroup } from '@angular/forms';

Quill.register('modules/blotFormatter', BlotFormatter);

Quill.register('modules/htmlEditButton', htmlEditButton);
let Inline = Quill.import('blots/inline');
let Block = Quill.import('blots/block');
let BlockEmbed = Quill.import('blots/block/embed');
declare var $:any;

class BoldBlot extends Inline { }
BoldBlot['blotName'] = 'bold';
BoldBlot['tagName'] = 'strong';

class DividerBlot extends BlockEmbed { }
DividerBlot['blotName'] = 'divider';
DividerBlot['tagName'] = 'hr';



interface BetterTableModule {
  insertTable(rows: number, columns: number): void;
}



Quill.register(BoldBlot);
Quill.register(DividerBlot);
Quill.debug('false');

@Component({
  selector: 'app-page-quill-block',
  templateUrl: './page-quill-block.component.html',
  styleUrls: ['./page-quill-block.component.scss']
})
export class PageQuillBlockComponent implements OnInit {
  @ViewChild("tooltips") tooltips: ElementRef;
  @ViewChild("sideControls") sideControls: ElementRef;
  @Input() public form: FormGroup;
  showTooltips: boolean = true;
  toggleFullSide: boolean = false;
  showSideControls: boolean = true;
  sideBarPosLeft;
  sideBarPosTop;
  toolTipPosLeft;
  toolTipPosBottom;
  quillEditor;
  color: string = '#ffffff';
  isBold: boolean = false;
  isList: boolean = false;
  isCB: boolean = false;
  isIC: boolean = false;
  isItalic: boolean = false;
  isSup: boolean = false;
  isSub: boolean = false;
  isUnd: boolean = false;
  isH1: boolean = false;
  isH2: boolean = false;
  isH3: boolean = false;
  isH4: boolean = false;
  isH5: boolean = false;
  isH6: boolean = false;
  isLink: boolean = false;
  isBQ: boolean = false;
  quillModules: QuillModules = {
    syntax: true,
    toolbar: '#tooltip-controls',
    blotFormatter: {
      // see config options below
    },
    htmlEditButton: {
      debug: false,
      syntax: true,
      msg: "Edit HTML content",
      okText: "Save",
      buttonHTML: "<span style='color:white; font-weight: 700'; width:100px;><></span>"
    },
  }



  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    hljs.configure({   // optionally configure hljs
      languages: ['R', 'python', 'bash']
    });
  }

  editorStarted(quill){
    quill.addContainer(this.tooltips.nativeElement);
    quill.addContainer(this.sideControls.nativeElement);
    this.showTooltips = false;
    this.showSideControls = false;
    let sc = this.sideControls;
    quill.on(Quill.events.EDITOR_CHANGE, (eventType, range, oldRange, source) => {
      if (eventType !== Quill.events.SELECTION_CHANGE) return;
      if (range == null) {
        this.showSideControls = false;
        return;
      };
      if (range.length === 0) {
        this.showTooltips = false;
        let [block, offset] = quill.scroll.descendant(Block, range.index);
        if (block != null && block.domNode.firstChild instanceof HTMLBRElement) {
          console.log('1')
          let lineBounds = quill.getBounds(range);
          this.showSideControls = true;
          this.sideBarPosLeft = 15;
          let stepHeadersHeightAndTitleHeight = 0;
          // if(this.form.value.lessonTitle){
          //   stepHeadersHeightAndTitleHeight = (this.stepIndex * 72) + 32
          // } else {
          //   stepHeadersHeightAndTitleHeight = (this.stepIndex * 72)
          // }
          
          if((window.scrollY < 0)){
            this.sideBarPosTop = block.domNode.getBoundingClientRect().top;
          } else {
            this.sideBarPosTop = block.domNode.getBoundingClientRect().top  + window.scrollY;
          }
          

        } else {
          this.showTooltips = false;
          this.showSideControls = false;
          this.toggleFullSide = false;
        }
      } else {
        this.showTooltips = false;
        this.showSideControls = false;
        this.toggleFullSide = false;
        let rangeBounds = quill.getBounds(range);
        let [block, offset] = quill.scroll.descendant(Block, range.index);
        this.showTooltips = true;

        this.toolTipPosLeft = block.domNode.getBoundingClientRect().left + rangeBounds.left

        
        if((window.scrollY < 0)){
          this.toolTipPosBottom = block.domNode.getBoundingClientRect().top + 27;
        } else {
          this.toolTipPosBottom = rangeBounds.top + 165;
        }
      }
    });

    this.quillEditor = quill;
  }



  selectionChange($selectionChange){
    // console.log(this.quillEditor)

    if($selectionChange.range !== null){
      const formats = this.quillEditor.getFormat($selectionChange.range);
      this.isBold = false;
      this.isItalic = false;
      this.isUnd = false;
      this.isSub = false;
      this.isSup = false;
      this.isH1 = false;
      this.isH2 = false;
      this.isList = false;
      this.isCB = false;
      this.isBQ = false;
      this.isIC = false;
      for (const [format, isActive] of Object.entries(formats)) {
        if(format === 'bold'){
          this.isBold = true;
        }
        if(format === 'italic'){
          this.isItalic = true;
        }
        if(format === 'underline'){
          this.isUnd = true;
        }
        if(format === "script" && isActive === "sub"){
          this.isSub = true;
        }
        if(format === "script" && isActive === "super"){
          this.isSup = true;
        }
        if(format === 'header'){
          switch(isActive){
            case 1:{
              this.isH1 = true;
              break;
            }
            case 2:{
              this.isH2 = true;
              break;
            }
          }
        }
        if(format === "list" && isActive === "bullet"){
          this.isList = true;
        }
        if(format === 'code-block'){
          this.isCB = true;
        }
        if(format === 'blockquote'){
          this.isBQ = true;
        }
        if(format === 'code'){
          this.isIC = true;
        }
        if(format === 'link'){
          this.isLink = true;
        }
        
      }
    }
  }

  toggleSideControls(){
    this.toggleFullSide = !this.toggleFullSide;
    // $('#sidebar-controls').toggleClass('active');
    this.quillEditor.focus();
  }

  get quillSelectionFormat(){
    return this.quillEditor.getFormat()
  }

  formatBold(){
    const format = this.quillSelectionFormat;
    if(format.bold){
      this.quillEditor.format('bold', false, 'user');
      this.isBold=false;
      // $('#bold-button').removeClass('active');
    } else {
      this.quillEditor.format('bold', true, 'user');
      this.isBold=true;
      // $('#bold-button').addClass('active');
    }
    console.log(this.form)
  }

  formatCode(){
    const format = this.quillSelectionFormat;
    if(format['code-block']){
      this.quillEditor.format('code-block', false, 'user');
      this.isCB = false;
    } else {
      this.quillEditor.format('code-block', true, 'user');
      this.isCB = true;
    }
    this.showSideControls = false;
    this.toggleFullSide = false;
  }

  formatItalic(){
    const format = this.quillSelectionFormat;
    if(format.italic){
      this.quillEditor.format('italic', false, 'user');
      this.isItalic = false;
    } else {
      this.quillEditor.format('italic', true, 'user');
      this.isItalic = true;
    }
  }

  formatUnderlined(){
    const format = this.quillSelectionFormat;
    if(format.underline){
      this.quillEditor.format('underline', false, 'user');
      this.isUnd = false
    } else {
      this.quillEditor.format('underline', true, 'user');
      this.isUnd = true
    }
  }



  formatH1(){
    const format = this.quillSelectionFormat;
    if(format.header){
      this.quillEditor.format('header', 0, 'user');
      this.isH1 = false;
    } else {
      this.quillEditor.format('header', 1, 'user');
      this.isH1 = true;
    }
  }

  formatH2(){
    const format = this.quillSelectionFormat;
    if(format.header){
      this.quillEditor.format('header', 0, 'user');
      this.isH2 = false;
    } else {
      this.quillEditor.format('header', 2, 'user');
      this.isH2 = true;
    }
  }

  formatH3(){
    const format = this.quillSelectionFormat;
    if(format.header){
      this.quillEditor.format('header', 0, 'user');
      this.isH3 = false;
    } else {
      this.quillEditor.format('header', 3, 'user');
      this.isH3 = true;
    }
  }

  formatH4(){
    const format = this.quillSelectionFormat;
    if(format.header){
      this.quillEditor.format('header', 0, 'user');
      this.isH4 = false;
    } else {
      this.quillEditor.format('header', 4, 'user');
      this.isH4 = true;
    }
  }

  formatH5(){
    const format = this.quillSelectionFormat;
    if(format.header){
      this.quillEditor.format('header', 0, 'user');
      this.isH5 = false;
    } else {
      this.quillEditor.format('header', 5, 'user');
      this.isH5 = true;
    }
  }

  formatH6(){
    const format = this.quillSelectionFormat;
    if(format.header){
      this.quillEditor.format('header', 0, 'user');
      this.isH6 = false;
    } else {
      this.quillEditor.format('header', 6, 'user');
      this.isH6 = true;
    }
  }

  formatInlineCode(){
    const format = this.quillSelectionFormat;
    if(format.code){
      this.quillEditor.format('code', false, 'user');
      this.isIC = false;
    } else {
      this.quillEditor.format('code', true, 'user');
      this.isIC = true;
    }
  }

  addVideo(){
    let range = this.quillEditor.getSelection(true);
    const dialogRef = this.dialog.open(EmbedDialogComponent, {
      data: {embedType: 'Youtube video Id', url: ''}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.quillEditor.insertEmbed(range.index, 'video', `https://www.youtube.com/embed/${result}`, Quill.sources.USER);
        this.quillEditor.formatText(range.index, 1, { height: '300', width: '60%'});
        this.quillEditor.setSelection(range.index + 1, Quill.sources.SILENT);
      }
    });
    // let url = 'https://www.youtube.com/embed/QHH3iSeDBLo?showinfo=0';
    this.showSideControls = false;
    this.toggleFullSide = false;
  }

  addImage(){
    let range = this.quillEditor.getSelection(true);
    const dialogRef = this.dialog.open(EmbedDialogComponent, {
      data: {embedType: 'image URL', url: ''}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.quillEditor.insertEmbed(range.index, 'image', result, Quill.sources.USER);
        // this.quillEditor.formatText(range.index, range.index+1, {'width': '500px'});
        this.quillEditor.setSelection(range.index + 1, Quill.sources.SILENT);
      }
    });
    this.showSideControls = false;
    this.toggleFullSide = false;
  }

  formatLink(){
    const dialogRef = this.dialog.open(EmbedDialogComponent, {
      data: {embedType: 'link URL', url: ''}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.quillEditor.format('link', result, 'user');
      }
    });
  }

  formatQuote(){
    const format = this.quillSelectionFormat;
    if(format.blockquote){
      this.quillEditor.format('blockquote', false, 'user');
      this.isBQ = false;
    } else {
      this.quillEditor.format('blockquote', true, 'user');
      this.isBQ = true;
    }
  }

  formatList(){
    const format = this.quillSelectionFormat;
    if(format.list){
      this.quillEditor.format('list', false, 'user');
      this.isList = false;
    } else {
      this.quillEditor.format('list', true, 'user');
      this.isList = true;
    }
  }

  formatSub(){
    const format = this.quillSelectionFormat;
    if(format.script){
      this.quillEditor.format('script', false, 'user');
      this.isSub = false;
    } else {
      this.quillEditor.format('script', "sub", 'user');
      this.isSub = true;
    }
  }

  formatSup(){
    const format = this.quillSelectionFormat;
    if(format.script){
      this.quillEditor.format('script', false, 'user');
      this.isSup = false;
    } else {
      this.quillEditor.format('script', "super", 'user');
      this.isSup = true;
    }
  }

  addDivider(){
    let range = this.quillEditor.getSelection(true);
    this.quillEditor.insertEmbed(range.index, 'divider', true, Quill.sources.USER);
    this.quillEditor.setSelection(range.index + 1, Quill.sources.SILENT);
    this.showSideControls = false;
    this.toggleFullSide = false;
    // $('#sidebar-controls').hide();
  }

  clearFormatting(){
    const selection = this.quillEditor.getSelection();
    this.quillEditor.removeFormat(selection, 'user');
  }



   $create(elName) {
    return document.createElement(elName);
  }
   $setAttr(el, key, value) {
    return el.setAttribute(key, value);
  } 

   formatHTML(code) {
    "use strict";
    let stripWhiteSpaces = true;
    let stripEmptyLines = true;
    const whitespace = " ".repeat(2); // Default indenting 4 whitespaces
    let currentIndent = 0;
    const newlineChar = "\n";
    let prevChar = null;
    let char = null;
    let nextChar = null;
  
    let result = "";
    for (let pos = 0; pos <= code.length; pos++) {
      prevChar = char;
      char = code.substr(pos, 1);
      nextChar = code.substr(pos + 1, 1);
  
      const isBrTag = code.substr(pos, 4) === "<br>";
      const isOpeningTag = char === "<" && nextChar !== "/" && !isBrTag;
      const isClosingTag = char === "<" && nextChar === "/" && !isBrTag;
      const isTagEnd = prevChar === ">" && char !== "<" && currentIndent > 0;
      const isTagNext = !isBrTag && !isOpeningTag && !isClosingTag && isTagEnd && code.substr(pos, code.substr(pos).indexOf("<")).trim() === "";
      if (isBrTag) {
        // If opening tag, add newline character and indention
        result += newlineChar;
        currentIndent--;
        pos += 4;
      }
      if (isOpeningTag) {
        // If opening tag, add newline character and indention
        result += newlineChar + whitespace.repeat(currentIndent);
        currentIndent++;
      }
      // if Closing tag, add newline and indention
      else if (isClosingTag) {
        // If there're more closing tags than opening
        if (--currentIndent < 0) currentIndent = 0;
        result += newlineChar + whitespace.repeat(currentIndent);
      }
      // remove multiple whitespaces
      else if (stripWhiteSpaces === true && char === " " && nextChar === " ")
        char = "";
      // remove empty lines
      else if (stripEmptyLines === true && char === newlineChar) {
        //debugger;
        if (code.substr(pos, code.substr(pos).indexOf("<")).trim() === "")
          char = "";
      }
      if(isTagEnd && !isTagNext) {
        result += newlineChar + whitespace.repeat(currentIndent);
      }
  
      result += char;
    }

    return result;
  }

   launchPopupEditor() {
     let quill = this.quillEditor
     let options = {
      syntax: true,
      msg: "Edit HTML content",
      okText: "Save",
      cancelText: '',
      buttonHTML: "<span style='color:white; font-weight: 700'; width:100px;><></span>"
    }
    const htmlFromEditor = quill.container.querySelector(".ql-editor").innerHTML;
    const popupContainer = this.$create("div");
    const overlayContainer = this.$create("div");
    const msg = options.msg || 'Edit HTML here, when you click "OK" the quill editor\'s contents will be replaced';
    const cancelText = options.cancelText || "Cancel";
    const okText = options.okText || "Ok";
  
    this.$setAttr(overlayContainer, "class", "ql-html-overlayContainer");
    this.$setAttr(popupContainer, "class", "ql-html-popupContainer");
    const popupTitle = this.$create("i");
    this.$setAttr(popupTitle, "class", "ql-html-popupTitle");
    popupTitle.innerText = msg;
    const textContainer = this.$create("div");
    textContainer.appendChild(popupTitle);
    this.$setAttr(textContainer, "class", "ql-html-textContainer");
    const codeBlock = this.$create("pre");
    this.$setAttr(codeBlock, "data-language", "xml");
    codeBlock.innerText = this.formatHTML(htmlFromEditor);
    const htmlEditor = this.$create("div");
    this.$setAttr(htmlEditor, "class", "ql-html-textArea");
    const buttonCancel = this.$create("button");
    buttonCancel.innerHTML = cancelText;
  this.$setAttr(buttonCancel, "class", "ql-html-buttonCancel");
    const buttonOk = this.$create("button");
    buttonOk.innerHTML = okText;
    const buttonGroup = this.$create("div");
    this.$setAttr(buttonGroup, "class", "ql-html-buttonGroup");
  
    buttonGroup.appendChild(buttonCancel);
    buttonGroup.appendChild(buttonOk);
    htmlEditor.appendChild(codeBlock);
    textContainer.appendChild(htmlEditor);
    textContainer.appendChild(buttonGroup);
    popupContainer.appendChild(textContainer);
    overlayContainer.appendChild(popupContainer);
    document.body.appendChild(overlayContainer);
    var editor = new Quill(htmlEditor, {
      modules: { syntax: options.syntax },
    });
  
    buttonCancel.onclick = function() {
      document.body.removeChild(overlayContainer);
    };
    overlayContainer.onclick = buttonCancel.onclick;
    popupContainer.onclick = function(e) {
      e.preventDefault();
      e.stopPropagation();
    };
    buttonOk.onclick = function() {
      const output = editor.container.querySelector(".ql-editor").innerText;
      const noNewlines = output
      .replace(/\s+/g, " ") // convert multiple spaces to a single space. This is how HTML treats them
      .replace(/(<[^\/<>]+>)\s+/g, "$1") // remove spaces after the start of a new tag
      .replace(/<\/(p|ol|ul)>\s/g, "</$1>") // remove spaces after the end of lists and paragraphs, they tend to break quill
      .replace(/\s<(p|ol|ul)>/g, "<$1>") // remove spaces before the start of lists and paragraphs, they tend to break quill
      .replace(/<\/li>\s<li>/g, "</li><li>") // remove spaces between list items, they tend to break quill
      .replace(/\s<\//g, "</") // remove spaces before the end of tags
      .replace(/(<[^\/<>]+>)\s(<[^\/<>]+>)/g, "$1$2") // remove space between multiple starting tags
      .trim();
      quill.container.querySelector(".ql-editor").innerHTML = noNewlines;
      document.body.removeChild(overlayContainer);
    };
  }



}
