// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyD7jyCxl2YcC03RrT66Qp4qOyAso9yjB14",
    authDomain: "omigcslogic-code-dev.firebaseapp.com",
    databaseURL: "https://omigcslogic-code-dev.firebaseio.com",
    projectId: "omigcslogic-code-dev",
    storageBucket: "omigcslogic-code-dev.appspot.com",
    messagingSenderId: "242539175067",
    appId: "1:242539175067:web:66761e700e2a1c6f757a3e",
    measurementId: "G-RNBKLG2E72"
  },
  meiliseach: {
    url: "https://devsearch.omicsdiet.com",
    key: "443b75cee2427a78bc78073f008235c4a8ea16db353d9f2c6ce983dfa31fcf53"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
