<div #quillContainer [formGroup]="form">

  <quill-editor theme="bubble" [modules]="quillModules" formControlName="content" [bounds]='quillContainer'
  placeholder="A long time ago, in a galaxy far, far away..." (onEditorCreated)="editorStarted($event)"
  (onSelectionChanged)="selectionChange($event)">

  <div quill-editor-toolbar *ngIf="showTooltips" id="tooltip-controls" #tooltips [style.top.px]="toolTipPosBottom"  [style.left.px]="toolTipPosLeft">
    <div fxLayout="row" fxLayout="start center">
      <button [ngClass]="{'active': isBold}" id="bold-button" title="Bold" (click)="formatBold()">
        <mat-icon>format_bold</mat-icon>
      </button>
      <button [ngClass]="{'active': isItalic}" id="italic-button" title="Italic" (click)="formatItalic()">
        <mat-icon>format_italic</mat-icon>
      </button>
      <button  [ngClass]="{'active': isUnd}" id="underline-button" title="Underline" (click)="formatUnderlined()">
        <mat-icon> format_underlined</mat-icon>
      </button>
      <button [ngClass]="{'active': isSub}" id="sub-button" title="Subscript" (click)="formatSub()"><sub>2</sub></button>
      <button [ngClass]="{'active': isSup}" id="super-button" title="Superscript" (click)="formatSup()"><sup>2</sup></button>
      <mat-divider [vertical]="true" style="background-color:#1490d8; margin: 0px 12px"></mat-divider>
      <button [ngClass]="{'active': isH1}" id="header-1-button" title="Heading 1" (click)="formatH1()">H<sub>1</sub></button>
      <button [ngClass]="{'active': isH2}" id="header-2-button" title="Heading 2" (click)="formatH2()">H<sub>2</sub></button>
      <button [matMenuTriggerFor]="menu">H<sub>+</sub></button>
      <mat-menu #menu="matMenu" theme="dark">
        <button (click)="formatH3()" title="Heading 3" style="color: black; margin:0" id="header-3-button"
          mat-menu-item>H3</button>
        <button (click)="formatH4()" title="Heading 4" style="color: black; margin:0" id="header-4-button"
          mat-menu-item>H4</button>
        <button (click)="formatH5()" title="Heading 5" style="color: black; margin:0" id="header-5-button"
          mat-menu-item>H5</button>
        <button (click)="formatH6()" title="Heading 6" style="color: black; margin:0" id="header-6-button"
          mat-menu-item>H6</button>
      </mat-menu>
      <mat-divider [vertical]="true" style="background-color:#1490d8; margin: 0px 12px"></mat-divider>
      <button [ngClass]="{'active': isLink}" id="link-button" title="Link Format" (click)="formatLink()">
        <mat-icon>link</mat-icon>
      </button>
      <button [ngClass]="{'active': isList}" id="list-button" title="List Format" (click)="formatList()">
        <mat-icon>format_list_bulleted</mat-icon>
      </button>
      <button [ngClass]="{'active': isCB}" id="code-block-button" (click)="formatCode()" title="Codeblock">
        <mat-icon>code</mat-icon>
      </button>
      <button [ngClass]="{'active': isIC}" id="inline-code-button" (click)="formatInlineCode()" title="Inline code">
        <mat-icon> format_quote</mat-icon>
      </button>
      <button id="blockquote-button" title="Tip" (click)="formatQuote()">
        <i class="fa fa-lightbulb-o" aria-hidden="true"></i>
      </button>
      <mat-divider [vertical]="true" style="background-color:#1490d8; margin: 0px 12px"></mat-divider>
      <button  id="clear-button" (click)="clearFormatting()" title="Clear formatting">
        <mat-icon> format_clear</mat-icon>
      </button>
      <button id="html-edit-button" (click)="launchPopupEditor()" title="Edit HTML Code">
        <mat-icon>edit</mat-icon>
      </button>
    </div>

  </div>

  <div quill-editor-toolbar id="sidebar-controls" #sideControls *ngIf="showSideControls" [style.top.px]="sideBarPosTop" [style.left.vw]="sideBarPosLeft" [ngClass]="{'active': toggleFullSide}">
    <button id="show-controls" (click)="toggleSideControls()" >
      <mat-icon>add_circle_outline</mat-icon>
    </button>
    <span class="controls" *ngIf="toggleFullSide">
      <button (click)="addImage()" id="image-button" title="Embed Image">
        <mat-icon>image</mat-icon>
      </button>
      <button (click)="addVideo()" id="video-button" title="Embed Youtube">
        <mat-icon>play_arrow</mat-icon>
      </button>
      <!-- <button id="table-button" title="Add table" (click)="addTable()">
        <mat-icon>table_chart</mat-icon>
      </button> -->
      <button id="code-block-button" (click)="formatCode()" title="Add Codeblock">
        <mat-icon>code</mat-icon>
      </button>
      <button (click)="addDivider()" id="divider-button" title="Add Divider">
        <mat-icon>minimize</mat-icon>
      </button>
    </span>
  </div>


</quill-editor>

</div>