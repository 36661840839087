<div class="dropzone" 
     appDropzone
     (hovered)="toggleHover($event)"
     (dropped)="onDrop($event)"
     [class.hovering]="isHovering">

     <p>Drag and Drop a File</p>
</div>

<mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Uploads
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div style="max-height: 500px; overflow-y:auto; overflow-x:hidden">
        <div *ngFor="let file of uploadedFiles">
          <app-uploaded-file  [uploadedFile]="file"></app-uploaded-file>
        </div>
      </div>

    </mat-expansion-panel>
    <mat-expansion-panel >
      <mat-expansion-panel-header>
        <mat-panel-title>
          New Uploads
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div style="max-height: 500px; overflow-y:auto; overflow-x:hidden">
      <div *ngFor="let file of files">
        <upload-task [uploadPath]="uploadPath" [fileCollectionPath]="fileCollectionPath" [file]="file"></upload-task>
      </div>
    </div>
    </mat-expansion-panel>
  </mat-accordion>

