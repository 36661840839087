import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../models/user.model';
import { DatabaseService } from 'src/app/shared/services/database/database.service'

@Component({
  selector: 'app-top-users-card',
  templateUrl: './top-users-card.component.html',
  styleUrls: ['./top-users-card.component.scss']
})
export class TopUsersCardComponent implements OnInit {
  topUsers: Observable<User[]>;

  constructor(private db: DatabaseService) { }

  ngOnInit(): void {
    this.topUsers = this.db.getTopUsers(5);
  }

  getRankColors(rank: number){
    switch(rank) { 
      case 0: { 
         return 'gold';
      } 
      case 1: { 
         return 'silver';
      } 
      case 2:{
        return '#cd7f32';
      }
      default: { 
         return 'black';
      } 
   } 

  }

}
