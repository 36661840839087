import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Location} from '@angular/common';


@Component({
  selector: 'app-text-field-dialog',
  templateUrl: './text-field-dialog.component.html',
  styleUrls: ['./text-field-dialog.component.scss']
})
export class TextFieldDialogComponent implements OnInit {
  currentUrl: string;


  constructor(
    public dialogRef: MatDialogRef<TextFieldDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _location: Location) { }

  ngOnInit(): void {
    const angularRoute = this._location.path();
    const url = window.location.href;
    this.currentUrl = url.replace(angularRoute, '');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
