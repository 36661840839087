<h1 mat-dialog-title>Enter {{data.embedType}}</h1>
<div mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>Embed {{data.embedType}}</mat-label>
        <input  autocomplete="off" cdkFocusInitial matInput [(ngModel)]="data.url">
      </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
  <button mat-button [mat-dialog-close]="data.url" [disabled]="data.url.length == 0">Ok</button>
</div>