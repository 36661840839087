<div style="max-width: 220px;">
    <div fxLayout="row wrap" fxLayoutAlign="start center">
        <a [href]="uploadedFile.shortLink.shortLink" target="_blank">{{uploadedFile.fileName}}</a>
        <button mat-icon-button (click)="openDeleteDialog()" title="Delete file">
            <mat-icon>delete</mat-icon>
        </button>
    </div>

    <div fxLayout="row">
      <button mat-icon-button [cdkCopyToClipboard]="uploadedFile.downloadURL" title="Copy full link">
        <mat-icon>reorder</mat-icon>
      </button>
      <button mat-icon-button [cdkCopyToClipboard]="uploadedFile.shortLink.shortLink" title="Copy short link">
        <mat-icon>link</mat-icon>
      </button>
    </div>

</div>

