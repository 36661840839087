import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPageComponent } from './login-page/login-page.component';
import { AdminGuard } from './guards/admin/admin.guard';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { AdminLoadGuard } from './guards/admin-load/admin-load.guard';
import { EditorGuard } from './guards/editor/editor.guard';
import { AngularFireAuthGuard, redirectLoggedInTo } from '@angular/fire/auth-guard';
const redirectLoggedInToDash = () => redirectLoggedInTo(["/dashboard"]);



const routes: Routes = [
  {path: '',component: LoginPageComponent,    
  canActivate: [AngularFireAuthGuard],
  data: { authGuardPipe: redirectLoggedInToDash }
},
  {path: 'dashboard',component: DashboardPageComponent, canActivate: [EditorGuard], canLoad:[EditorGuard]},
  {
    path: 'code', loadChildren: () => import('./code/code.module').then(m => m.CodeModule),
    canActivate: [EditorGuard],
    canLoad: [EditorGuard]
  },
  {
    path: 'programs', loadChildren: () => import('./programs/programs.module').then(m => m.ProgramsModule),
    canActivate: [EditorGuard],
    canLoad: [EditorGuard]
  },
  {
    path: 'users', loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    canActivate: [AdminGuard],
    canLoad: [AdminLoadGuard]
  },
  {
    path: 'projects', loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule),
    canActivate: [AdminGuard],
    canLoad: [AdminLoadGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
