<div class="card-back">
    <h4>Top Courses:</h4>
    <div *ngIf="topCourses |async as topCourses">
        <div *ngFor="let course of topCourses; let lastItem = last;">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px" style="padding: 6px 0; font-size:12px">
                <div >{{course.courseTitle}}</div>
                <strong title="Times completed" style="background-color: aquamarine; padding: 4px; border-radius:0.5em;">{{course.timesCompleted}}</strong>
            </div>
            <mat-divider *ngIf="!lastItem"></mat-divider>
        </div>
    </div>
</div>