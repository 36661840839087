<div class="card-back">
    <h4>Top Lessons:</h4>
    <div *ngIf="topLessons |async as topLessons">
        <div *ngFor="let lesson of topLessons;; let lastItem = last;">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px" style="padding: 6px 0; font-size:12px">
                <div style="padding: 6px 0; font-size:12px">{{lesson.lessonTitle}}</div>
                <strong title="Times completed" style="background-color: aquamarine; padding: 4px; border-radius:0.5em;">{{lesson.timesCompleted}}</strong> 
            </div>
            <mat-divider *ngIf="!lastItem"></mat-divider>
        </div>
    </div>
</div>