import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Lesson } from '../../models/course.model';
import { DatabaseService } from 'src/app/shared/services/database/database.service'
@Component({
  selector: 'app-top-lessons-card',
  templateUrl: './top-lessons-card.component.html',
  styleUrls: ['./top-lessons-card.component.scss']
})
export class TopLessonsCardComponent implements OnInit {
  topLessons: Observable<Lesson[]>;

  constructor(private db: DatabaseService) { }

  ngOnInit(): void {
    this.topLessons = this.db.getTopLessons();
  }

}
