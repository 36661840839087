import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';



//firebase
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireFunctionsModule } from '@angular/fire/functions';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { LoginPageComponent } from './login-page/login-page.component';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { DropzoneDirective } from './directives/dropzone.directive';
import { RecentActivityCardComponent } from './components/recent-activity-card/recent-activity-card.component';
import { TopLessonsCardComponent } from './components/top-lessons-card/top-lessons-card.component';
import { TopCoursesCardComponent } from './components/top-courses-card/top-courses-card.component';
import { TopUsersCardComponent } from './components/top-users-card/top-users-card.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    DashboardPageComponent,
    RecentActivityCardComponent,
    TopLessonsCardComponent,
    TopCoursesCardComponent,
    TopUsersCardComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    BrowserAnimationsModule,
    SharedModule,
  ],
  providers: [DropzoneDirective],
  bootstrap: [AppComponent]
})
export class AppModule { }
