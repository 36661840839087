import { Component, OnInit, Input } from '@angular/core';
import { Achievement } from 'src/app/models/course.model';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user.model'
import { DatabaseService } from 'src/app/shared/services/database/database.service';

@Component({
  selector: 'app-user-activity-tile',
  templateUrl: './user-activity-tile.component.html',
  styleUrls: ['./user-activity-tile.component.scss']
})
export class UserActivityTileComponent implements OnInit {
  @Input() achievement: Achievement;
  userProfile: Observable<User>
  constructor(private db: DatabaseService) { }

  ngOnInit(): void {
    this.userProfile = this.db.getStudentProfile(this.achievement.uid);
  }

}
